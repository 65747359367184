<template>
  <div class="main">
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体">更新日期：</span
        ><span style="font-family: 宋体">2023年</span></span
      ><span style="font-family: 宋体; font-size: 14px">5</span
      ><span style="font-family: 宋体; font-size: 14px">月</span
      ><span style="font-family: 宋体; font-size: 14px">31</span
      ><span style="font-family: 宋体; font-size: 14px">日</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体">生效日期：</span
        ><span style="font-family: 宋体">2023年</span></span
      ><span style="font-family: 宋体; font-size: 14px">5</span
      ><span style="font-family: 宋体; font-size: 14px">月</span
      ><span style="font-family: 宋体; font-size: 14px">31</span
      ><span style="font-family: 宋体; font-size: 14px">日</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">隐私政策</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">感谢您使用</span
      ><span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体">星科</span
        ><span style="font-family: 宋体">AI</span></span
      ><span style="font-family: 宋体; font-size: 14px">！本应用是由</span
      ><span style="font-family: 宋体; font-size: 14px"
        >北京星润时代网络科技有限公司</span
      ><span style="font-family: 宋体; font-size: 14px"
        >为您提供的一款社交问答工具类产品。本应用尊重并保护所有使用服务的用户个人隐私权。为了给您提供更好的服务，本应用会按照本隐私政策的规定使用和披露您的个人信息。我们将以高度的勤勉、审慎义务对待这些信息。除本隐私政策相关规定外，在未得到您允许的情况下，本应用不会将这些信息对外披露或向第三方提供。本应用会不定时更新隐私政策。您在同意本应用服务使用协议之时，即视为您已经同意本隐私政策全部内容，本隐私政策属于本应用服务使用协议不可分割的一部分。</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >本隐私政策将帮助您了解在使用本应用的过程中可能涉及的内容，请您在使用我们的产品（或服务）前，请您仔细阅读以下内容：</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >我们如何收集和使用您的个人信息</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">信息披露</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">信息存储和交换</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">Cookie的使用</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">信息安全</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">本隐私政策的更改</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">儿童隐私相关</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">用户如何管理信息</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">知识产权相关</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">法律责任与免责申明</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >一、我们如何收集和使用您的个人信息</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。如我们收集、使用的信息涉及您的个人敏感信息的，我们将用加粗字体突出显示以向您明确标识。同时，我们将采取加密等安全措施传输和存储您的个人敏感信息。如果您公开发布的信息中涉及敏感信息、他人信息，请谨慎选择及考虑。若您公开发布的信息中涉及未成年人个人信息的，您需要在发布前征得对应未成年人的监护人同意。我们将您的个人信息用于本政策未载明的其他用途或目的时，会事先征求您的同意。</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >为了实现我们的产品、服务的核心功能，我们可能会收集、保存和使用下列与您有关的信息。如果您不提供相关信息，我们将无法为您提供相应的产品或服务。这些功能和信息内容如下</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >1、保障登录安全所必须的功能</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体">为保障您使用我们的产品与</span
        ><span style="font-family: 宋体"
          >/或服务时系统的稳定性和安全性，防止您的个人信息被非法获取，更准确地预防欺诈和保护账号安全，我们需要收集您的设备信息（</span
        ></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">获取已安装</span
        ><span style="font-family: 宋体">APP信息、获取传感器信息</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">、</span></span
      ><span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体">设备唯一</span
        ><span style="font-family: 宋体"
          >ID、MAC地址、AndroidID、设备序列号）等来判断您的账号登录风险，我们会用上述信息排查可能存在的系统风险、安全风险和信息异常。如果您不提供上述信息，我们无法对您的账号安全及账号权益进行保护。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体">当我们</span
        ><span style="font-family: 宋体"
          >app有新版本更新时，您选择更新我们新版本，会使用到您的app安装权限；</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">2</span
      ><span style="font-family: 宋体; font-size: 14px"
        >、收录声音转义成文字</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体">当您使用</span
        ><span style="font-family: 宋体"
          >app内语音键进行说话时，会开启您的麦克风权限（Manifest.permission.RECORD_AUDIO）用于录音，并通过第三方sdk（具体sdk详情信息见【第三方sdk清单】）转义成文字形式，用于发送内容。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">3</span
      ><span style="font-family: 宋体; font-size: 14px"
        >、合作接入的第三方获取的相关信息</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体"
          >为保障本应用内功能的实现、广告展示、</span
        ><span style="font-family: 宋体"
          >app的稳定运行，我们可能接入由其他第三方提供的软件开发包（sdk）、应用程序借口（api）等代码或通过合作方式，以实现相关目的。具体合作方及其可能获取相关信息，详情见【第三方sdk清单】。我们会对获取信息的合作方的代码进行严格安全检测，以保护数据安全。请注意，合作方的代码可能因其版本升级、策略调整原因导致数据处理类型存在变化，请以其公示的官网说明为准。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">二、信息披露</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >在如下情况下，本应用将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >①经您事先同意，向第三方披露；</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >②为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >③根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >④如您出现违反中国有关法律、法规或者本应用服务协议或相关规则的情况，需要向第三方披露；</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >⑤如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >⑥在本应用平台上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，本应用有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决。</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >⑦其它本应用根据法律、法规或者网站政策认为合适的披露。</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">三、信息存储和交换</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >本应用收集的有关您的信息和资料将保存在本应用及（或）其关联公司的服务器上，我们仅仅是把他存在服务器上，不会影响您的任何隐私情况。您所有产生的数据，我们不会共享面部数据给第三方，我们的服务器没有存储任何用户的面部数据。</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体">四、</span
        ><span style="font-family: 宋体">Cookie的使用</span></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体">在您未拒绝接受</span
        ><span style="font-family: 宋体"
          >cookies的情况下，本应用会在您的计算机上设定或取用cookies，以便您能登录或使用依赖于cookies的本应用平台服务或功能。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体">您有权选择接受或拒绝接受</span
        ><span style="font-family: 宋体"
          >cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的本应用网络服务或功能。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体">通过本应用所设</span
        ><span style="font-family: 宋体"
          >cookies所取得的有关信息，将适用本政策。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">五、信息安全</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体"
          >本应用帐号均有安全保护功能，请妥善保管您的用户名相关信息。本应用将通过对用户信息进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在</span
        ><span style="font-family: 宋体">“完善的安全措施”。</span></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >六、本隐私政策的更改</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >如果决定更改隐私政策，我们会在本政策中、本公司网站中以及我们认为适当的位置发布这些更改，以便您了解我们如何收集、使用您的个人信息，哪些人可以访问这些信息，以及在什么情况下我们会透露这些信息。本公司保留随时修改本政策的权利，因此请经常查看。如对本政策作出重大更改，本公司会通过网站通知的形式告知。</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">七、儿童隐私相关</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体"
          >我们注重儿童的隐私。虽然我们的应用程序可供儿童使用，但是只有家长和</span
        ><span style="font-family: 宋体"
          >/或法定监护人或其他成人可以购买商品。若要设置您的机器人，家长或监护人应下载应用程序。产品使用信息仅用于支持我们的内部运营。我们不会有意收集或征求
          13
          岁以下的任何人的个人信息。在进行适当的身份验证后，家长或法定监护人可以查看我们收集的关于儿童的信息，要求删除或拒绝允许进一步收集或使用这些信息。请记住，删除这些信息的请求可能会限制儿童访问所有或部分服务。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >八、用户如何管理信息</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体"
          >访问、更正和删除：您可通过手机系统</span
        ><span style="font-family: 宋体"
          >“设置”-“应用”-“应用管理”-“权限”-“</span
        ></span
      ><span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体">星科</span
        ><span style="font-family: 宋体">AI</span></span
      ><span style="font-family: 宋体; font-size: 14px"
        >”查看您的系统授权权限。若您需要查阅您在使用过程中产生的个人信息，在合理要求下，我们会向您提供。若您无法通过上述方式访问、更正或删除您的个人信息，也可以发送邮件至</span
      ><span style="font-family: 宋体; font-size: 14px">646457447</span
      ><span style="font-family: 宋体; font-size: 14px"
        >@联系我们，我们会在15个工作日内回复您。</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体"
          >关闭或者撤回授权：您可以在设备本身操作系统</span
        ><span style="font-family: 宋体"
          >“权限管理”中关闭存储权限，改变授权范围或撤回您的授权。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >注销：在符合本应用的服务协议约定条件及国家相关法律法规规定的情况下，您的账号可能被注销或删除。当账号注销或被删除后，与该账号相关的、该单项服务项下的全部服务资料和数据将依照单项服务的服务协议约定删除或处理。您也可以自主在本应用【设置】页面使用【注销账户】功能，在弹出弹窗内选择【继续注销】将账户注销删除。</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">九、知识产权相关</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >您了解并同意我方有权随时检查您所上传或发布的内容，如果发现您上传的内容不符合前述规定，我方有权删除或重新编辑或修改您所上传或发布的内容，且有权在不事先通知您的情况下停用您的账号。您亦了解、同意并保证，您所上传或发布的内容符合前述规定，是您的义务，而非我方，我方无任何对您上传或发布的内容进行主动检查、编辑或修改的义务。</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >若您在本软件上的上传或发布内容的行为给第三方带来损害或损失，第三方主张赔偿或衍生的任何其他权利的，由您独立承担全部法律责任，我方及合作方概不承担任何责任。</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >我方不对用户上传或发布的内容的合法性、正当性、完整性或品质作出任何保证，用户需自行承担因使用或依赖由软件所传送的内容或资源所产生的风险，我方在任何情况下对此种风险可能或实际带来的损失或损害都不负任何责任。</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >十、法律责任与免责申明</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体"
          >我方将会尽其商业上的合理努力以保护用户的设备资源及通讯的隐私性和完整性，但是用户承认和同意我方不能就此事提供任何保证。</span
        >
        <span style="font-family: 宋体"
          >我方可以根据用户的使用状态和行为，为了改进软件的功能、用户体验和服务，开发或调整软件功能。</span
        >
        <span style="font-family: 宋体"
          >我方为保障业务发展和调整的自主权，有权随时自行修改或中断软件服务而无需通知用户。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体"
          >在任何情况下用户因使用或不能使用本软件所产生的直接、间接、偶然、特殊及后续的损害及风险，我方及合作方不承担任何责任。</span
        >
        <span style="font-family: 宋体"
          >因技术故障等不可抗事件影响到服务的正常运行的，我方及合作方承诺在第一时间内与相关单位配合，及时处理进行修复，但用户因此而遭受的一切损失，我方及合作方不承担责任。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >AI模型发出的信息是由计算机生成，可能是虚构的，请您自行辨别是非。您在使用本应用咨询医疗、法律等相关问题时，本应用仅提供医疗建议和法律建议，不代表医疗服务法律服务或治疗。用户在使用本应用前必须咨询医生和相关法律人士，对医疗和法律决定负最终责任。本应用的信息及服务应仅供参考，不构成医疗建议法律建议和治疗方式，我们不承担由使用本应用而产生的任何责任。</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体">第三方</span
        ><span style="font-family: 宋体">sdk清单</span></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">1、</span
      ><span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体">微信</span>
        <span style="font-family: 宋体">SDK</span></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体">涉及个人信息：设备标识符（</span
        ><span style="font-family: 宋体"
          >Android如IMEI、Android
          ID、IDFA、GUID、IMSI、Serial）、MAC地址、WLAN接入点（如SSID、BSSID）</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >使用目的：支持微信登录</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >使用场景：在用户使用微信登录时使用</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >合作方主体：深圳市腾讯计算机系统有限公司</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体">收集方式：</span
        ><span style="font-family: 宋体">SDK采集</span></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体">合作方官网链接：</span
        ><span style="font-family: 宋体"
          >https://open.weixin.qq.com/</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">合作方隐私政策：</span
      ><a
        href="https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8"
        ><span style="text-decoration: underline"
          ><span
            style="font-family: 宋体; color: rgb(0, 0, 255); font-size: 14px"
            >https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8</span
          ></span
        ></a
      >
    </p>
    <p>
      <span style="font-family: 宋体; letter-spacing: 0; font-size: 14px"
        >&nbsp;</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; letter-spacing: 0; font-size: 14px"
        >2、</span
      ><span style="font-family: 宋体; letter-spacing: 0; font-size: 14px"
        >com.alipay(支付宝;阿里乘车码;阿里芝麻信用实名认证;芝麻认证)</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; letter-spacing: 0; font-size: 14px"
        >收集数据类型：设备识别信息</span
      ><span style="font-family: 宋体; letter-spacing: 0; font-size: 14px"
        >，</span
      ><span style="font-family: 宋体; letter-spacing: 0; font-size: 14px"
        ><span style="font-family: 宋体">软件安装列表，设备</span
        ><span style="font-family: 宋体">MAC地址</span></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; letter-spacing: 0; font-size: 14px"
        >使用目的：提供支付相关服务</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; letter-spacing: 0; font-size: 14px"
        >官网链接：</span
      ><a href="https://open.alipay.com/platform/home.htm"
        ><span style="text-decoration: underline"
          ><span
            style="
              font-family: 宋体;
              color: rgb(0, 0, 255);
              letter-spacing: 0;
              font-size: 14px;
            "
            >https://open.alipay.com/platform/home.htm</span
          ></span
        ></a
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        >&nbsp;</span
      >
    </p>
    <p style="margin-left: 0; text-indent: 0">
      <span style="font-family: 宋体; letter-spacing: 0; font-size: 14px"
        >3、</span
      ><span style="font-family: 宋体; letter-spacing: 0; font-size: 14px"
        >com.tencent.smtt(TBS腾讯浏览服务;腾讯浏览服务;腾讯X5浏览器)</span
      >
    </p>
    <p style="margin-left: 0; text-indent: 0">
      <span style="font-family: 宋体; letter-spacing: 0; font-size: 14px"
        >收集数据类型：设备识别信息</span
      ><span style="font-family: 宋体; letter-spacing: 0; font-size: 14px"
        >，</span
      ><span style="font-family: 宋体; letter-spacing: 0; font-size: 14px"
        ><span style="font-family: 宋体">软件安装列表，设备</span
        ><span style="font-family: 宋体">MAC地址</span></span
      >
    </p>
    <p style="margin-left: 0; text-indent: 0">
      <span style="font-family: 宋体; letter-spacing: 0; font-size: 14px"
        >使用目的：方便更好的了解用户行为以提升用户体验</span
      >
    </p>
    <p style="margin-left: 0; text-indent: 0">
      <span style="font-family: 宋体; letter-spacing: 0; font-size: 14px"
        ><span style="font-family: 宋体">官网链接</span
        ><span style="font-family: 宋体">:&nbsp;</span></span
      ><a href="https://tencent.com/"
        ><span style="text-decoration: underline"
          ><span
            style="
              font-family: 宋体;
              color: rgb(0, 0, 255);
              letter-spacing: 0;
              font-size: 14px;
            "
            >https://tencent.com</span
          ></span
        ></a
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">4、</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">MMA 中国广告监测通用SDK</span></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">涉及个人信息：</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">设备标识信息（</span
        ><span style="font-family: 宋体"
          >OAID、IMEI、Android ID等）、网络信息（连接的WIFI、MAC地址等）</span
        ></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">使用目的：</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">广告监测</span></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">使用场景：</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">广告投放过程中</span></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">合作方主体：</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">上海亦拓广告有限公司</span></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">收集方式：</span></span
      ><span style="font-family: 宋体; font-size: 14px">SDK采集</span>
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">合作方隐私政策：</span></span
      ><a href="https://mmachina.cn/sdk-privacy/"
        ><span style="text-decoration: underline"
          ><span
            style="
              font-family: 宋体;
              color: rgb(0, 0, 255);
              letter-spacing: 0;
              font-size: 14px;
              background: rgb(255, 255, 255);
            "
            ><span style="font-family: 宋体"
              >https://mmachina.cn/sdk-privacy/</span
            ></span
          ></span
        ></a
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">5、</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">优量汇</span
        ><span style="font-family: 宋体">SDK</span></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">涉及个人信息：</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体"
          >粗略位置信息、设备信息、标识符、应用信息、使用数据、诊断数据、等</span
        ></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">使用目的：</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">广告投放</span></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">使用场景：</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">广告投放过程中</span></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">合作方主体：</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体"
          >深圳市腾讯计算机系统有限公司</span
        ></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">收集方式：</span></span
      ><span style="font-family: 宋体; font-size: 14px">SDK采集</span>
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">合作方隐私政策：</span></span
      ><a href="https://e.qq.com/dev/help_detail.html?cid=2004&pid=5795"
        ><span style="text-decoration: underline"
          ><span
            style="
              font-family: 宋体;
              color: rgb(0, 0, 255);
              letter-spacing: 0;
              font-size: 14px;
              background: rgb(255, 255, 255);
            "
            ><span style="font-family: 宋体"
              >https://e.qq.com/dev/help_detail.html?cid=2004&amp;pid=5795</span
            ></span
          ></span
        ></a
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        >&nbsp;</span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">6、</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">百度联盟</span></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">涉及个人信息：</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体"
          >粗略位置信息、设备信息、标识符、应用信息、使用数据、诊断数据、等</span
        ></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">使用目的：</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">广告投放</span></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">使用场景：</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">广告投放过程中</span></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">合作方主体：</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">北京百度网讯科技有限公司</span></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">收集方式：</span></span
      ><span style="font-family: 宋体; font-size: 14px">SDK采集</span>
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">合作方隐私政策：</span></span
      ><a href="https://yingxiao.baidu.com/home/help/details?id=17597"
        ><span style="text-decoration: underline"
          ><span
            style="
              font-family: 宋体;
              color: rgb(0, 0, 255);
              letter-spacing: 0;
              font-size: 14px;
              background: rgb(255, 255, 255);
            "
            ><span style="font-family: 宋体"
              >https://yingxiao.baidu.com/home/help/details?id=17597</span
            ></span
          ></span
        ></a
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        >&nbsp;</span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">7、</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">快手联盟</span></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">涉及个人信息：</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体"
          >粗略位置信息、设备信息、标识符、应用信息、使用数据、诊断数据、等</span
        ></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">使用目的：</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">广告投放</span></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">使用场景：</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">广告投放过程中</span></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">合作方主体：</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">北京快手科技有限公司</span></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">收集方式：</span></span
      ><span style="font-family: 宋体; font-size: 14px">SDK采集</span>
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">合作方隐私政策：</span></span
      ><a href="https://u.kuaishou.com/home/help/detail/1337/1425"
        ><span style="text-decoration: underline"
          ><span
            style="
              font-family: 宋体;
              color: rgb(0, 0, 255);
              letter-spacing: 0;
              font-size: 14px;
              background: rgb(255, 255, 255);
            "
            ><span style="font-family: 宋体"
              >https://u.kuaishou.com/home/help/detail/1337/1425</span
            ></span
          ></span
        ></a
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        >&nbsp;</span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">8、</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">穿山甲联盟</span></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">涉及个人信息：</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体"
          >粗略位置信息、设备信息、标识符、应用信息、使用数据、诊断数据、等</span
        ></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">使用目的：</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">广告投放</span></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">使用场景：</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">广告投放过程中</span></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">合作方主体：</span></span
      ><span style="font-family: 宋体; letter-spacing: 0; font-size: 14px"
        >北京巨量引擎网络技术有限公司</span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">收集方式：</span></span
      ><span style="font-family: 宋体; font-size: 14px">SDK采集</span>
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">合作方隐私政策：</span></span
      ><a href="https://www.csjplatform.com/terms/compliance-guide"
        ><span style="text-decoration: underline"
          ><span
            style="
              font-family: 宋体;
              color: rgb(0, 0, 255);
              letter-spacing: 0;
              font-size: 14px;
              background: rgb(255, 255, 255);
            "
            ><span style="font-family: 宋体"
              >https://www.csjplatform.com/terms/compliance-guide</span
            ></span
          ></span
        ></a
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">9、</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">聚力阅盟</span></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">涉及个人信息：</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体"
          >粗略位置信息、设备信息、标识符、应用信息、使用数据、诊断数据、等</span
        ></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">使用目的：</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">广告投放</span></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">使用场景：</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">广告投放过程中</span></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">合作方主体：</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">杭州聚力阅盟科技有限公司</span></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">收集方式：</span></span
      ><span style="font-family: 宋体; font-size: 14px">SDK采集</span>
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">合作方隐私政策：</span></span
      ><a href="http://book.ibooksss.com/privacy"
        ><span style="text-decoration: underline"
          ><span
            style="
              font-family: 宋体;
              color: rgb(0, 0, 255);
              letter-spacing: 0;
              font-size: 14px;
              background: rgb(255, 255, 255);
            "
            ><span style="font-family: 宋体"
              >http://book.ibooksss.com/privacy</span
            ></span
          ></span
        ></a
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        >&nbsp;</span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">10、</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">Sigmob</span></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">涉及个人信息：</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体"
          >粗略位置信息、设备信息、标识符、应用信息、使用数据、诊断数据、等</span
        ></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">使用目的：</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">广告投放</span></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">使用场景：</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">广告投放过程中</span></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">合作方主体：</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">北京创智汇聚科技有限公司</span></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">收集方式：</span></span
      ><span style="font-family: 宋体; font-size: 14px">SDK采集</span>
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">合作方隐私政策：</span></span
      ><a
        href="file:///E:/%E5%BE%AE%E4%BF%A1%E6%96%87%E4%BB%B6/WeChat%20Files/WeChat%20Files/wxid_4fgyr10faqmf22/FileStorage/File/2023-06/%E6%98%9F%E7%A7%91AI%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96(1).docx#/Sigmob%E4%BD%BF%E7%94%A8%E6%8C%87%E5%8D%97/%E9%9A%90%E7%A7%81%E6%9D%A1%E6%AC%BE/%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96/"
        ><span style="text-decoration: underline"
          ><span
            style="
              font-family: 宋体;
              color: rgb(0, 0, 255);
              letter-spacing: 0;
              font-size: 14px;
              background: rgb(255, 255, 255);
            "
            ><span style="font-family: 宋体"
              >https://doc.sigmob.com/#/Sigmob%E4%BD%BF%E7%94%A8%E6%8C%87%E5%8D%97/%E9%9A%90%E7%A7%81%E6%9D%A1%E6%AC%BE/%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96/</span
            ></span
          ></span
        ></a
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        >&nbsp;</span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">11、</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">Mintegral</span></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">涉及个人信息：</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体"
          >粗略位置信息、设备信息、标识符、应用信息、使用数据、诊断数据、等</span
        ></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">使用目的：</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">广告投放</span></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">使用场景：</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">广告投放过程中</span></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">合作方主体：</span></span
      ><span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">广州汇世信息科技有限公司</span></span
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">收集方式：</span></span
      ><span style="font-family: 宋体; font-size: 14px">SDK采集</span>
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        ><span style="font-family: 宋体">合作方隐私政策：</span></span
      ><a
        href="https://dev.mintegral.com/doc/index.html?file=sdk-m_sdk-android&lang=cn"
        ><span style="text-decoration: underline"
          ><span
            style="
              font-family: 宋体;
              color: rgb(0, 0, 255);
              letter-spacing: 0;
              font-size: 14px;
              background: rgb(255, 255, 255);
            "
            ><span style="font-family: 宋体"
              >https://dev.mintegral.com/doc/index.html?file=sdk-m_sdk-android&amp;lang=cn</span
            ></span
          ></span
        ></a
      >
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        >&nbsp;</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">您的权利</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >（一）访问您的个人信息</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >您有权访问您的个人信息，法律法规规定的例外情况除外。</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >您可以通过发送电子邮件至</span
      ><span style="font-family: 宋体; font-size: 14px">646457447</span
      ><span style="font-family: 宋体; font-size: 14px"
        >@qq.com，要求访问您的个人信息，我们将在十五天内回复您的访问请求。</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >（二）更正您的个人信息</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >当您发现我们处理的关于您的个人信息有错误时，您有权要求我们作出更正。</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >您可以通过如下方式修改个人信息。</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">发送电子邮件至</span
      ><span style="font-family: 宋体; font-size: 14px">646457447</span
      ><span style="font-family: 宋体; font-size: 14px"
        >@qq.com提出更正申请我们将在十五天内回复您的更正请求。</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >（三）删除您的个人信息</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >在以下情形中，您可以向我们提出删除个人信息的请求：</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >1、如果我们处理个人信息的行为违反法律法规；</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >2、如果我们收集、使用您的个人信息，却未征得您的同意；</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >3、如果我们处理个人信息的行为违反了与您的约定；</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >4、如果您不再使用我们的产品或服务，或您注销了账号；</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >5、如果我们不再为您提供产品或服务。</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >若我们决定响应您的删除请求，我们还将同时告知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >当您从我们的服务中删除信息后，我们可能不会立即在备份系统中删除相应的信息，但会在备份更新时删除这些信息。</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >（四）改变您授权同意的范围</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >每个业务功能需要一些基本的个人信息才能得以完成。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意。</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >您可以通过系统设置内关于</span
      ><span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体">星科</span
        ><span style="font-family: 宋体">AI</span></span
      ><span style="font-family: 宋体; font-size: 14px">&nbsp;</span
      ><span style="font-family: 宋体; font-size: 14px"
        >APP的应用权限设置，逐项查看您上述个人信息的访问权限开启状态，并可以决定开启或关闭这些权限。</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功能，关闭这些权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >在以下情形中，我们将无法响应您的请求：</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >1、与个人信息控制者履行法律法规规定的义务相关的；</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >2、与国家安全、国防安全直接相关的；</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >3、与公共安全、公共卫生、重大公共利益直接相关的；</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >4、与刑事侦查、起诉、审判和执行判决等直接相关的；</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >5、个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的；</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >6、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >7、响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的；涉及商业秘密的。</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">未成年人保护</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体"
          >我们非常重视对未成年人个人信息的保护。根据相关法律法规的规定，若您是</span
        ><span style="font-family: 宋体"
          >18周岁以下的未成年人，在使用</span
        ></span
      ><span style="font-family: 宋体; font-size: 14px">星科</span
      ><span style="font-family: 宋体; font-size: 14px">AI</span
      ><span style="font-family: 宋体; font-size: 14px">&nbsp;</span
      ><span style="font-family: 宋体; font-size: 14px"
        >APP前，应事先取得您的家长或法定监护人的书面同意。若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有相关疑问时，请通过本隐私政策末尾的联系方式与我们联系。</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">本政策如何更新</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >我们的隐私政策可能变更。</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。我们会在本页面上发布对本政策所做的任何变更。</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体"
          >对于重大变更，我们还会提供更为显著的提示（包括我们会在</span
        ><span style="font-family: 宋体"
          >APP登录后采用弹窗的形式，说明个人信息保护政策的具体变更内容）。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >本政策所指的重大变更包括但不限于：</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >2、我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >3、个人信息共享、转让或公开披露的主要对象发生变化；</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >4、您参与个人信息处理方面的权利及其行使方式发生重大变化；</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >6、个人信息安全影响评估报告表明存在高风险时。</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">如何联系我们</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >如果您对本隐私政策有任何疑问、意见或建议，通过以下方式与我们联系：</span
      ><span style="font-family: 宋体; font-size: 14px">646457447@qq.com</span>
    </p>
    <p>
      <span
        style="
          font-family: 宋体;
          letter-spacing: 0;
          font-size: 14px;
          background: rgb(255, 255, 255);
        "
        >&nbsp;</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <br />
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style >
.main {
  padding: 20px;
}
</style>